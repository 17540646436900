.mapBlock{
    height:300px;
    width:100%;
    display: flex;
}

.round_select{
    margin-left: 15px;
    width:300px;
    display: flex;
    flex-direction: column;
}

.amap-div{
    width:665px;
    position: relative;
}

.amap-div .center-point{
    position: absolute;
    top:150px;
    left:317.5px;
    font-size: 30px;
    color:lightskyblue;
}
.mapAddress-now{
    margin-left: 15px;
}