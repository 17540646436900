@import '../../app.css';

.tableSelectBlock{
    padding:10px;
}

.ant-table-scroll{
    overflow: auto;
}

.tableSelectActionBar{
    margin-bottom: 5px
}

.tableSelectActionBar .ant-advanced-search-form .ant-form-item{
    display: flex;
    margin-bottom: 0px;
}

.ant-menu{
    border:1px solid #e8e8e8;
}