/*
.tableArea table {
  table-layout: fixed;
}
*/
.tableArea .ant-table-wrapper{
	height: 100%;
	padding: 0 16px;
}
.tableArea .ant-spin-nested-loading,
.tableArea .ant-spin-container,
.tableArea .ant-table-container{
	height: 100%;
}
.tableArea .ant-table{
	height: calc(100% - 25px - 32px);
	font-size: 14px;
}
.tableArea .ant-table .ant-table-container .ant-table-header tr,
.tableArea .ant-table .ant-table-container .ant-table-body tr{
	height: 40px;
}
.tableArea .ant-table .ant-table-container .ant-table-body tr:nth-child(2n) > td{
	/* background-color: rgb(242,242,242); */
}
.tableArea .ant-table .ant-table-container .ant-table-tbody > tr:hover,
.tableArea .ant-table .ant-table-container .ant-table-tbody > tr.ant-table-row:hover > td{
	background-color: rgb(241,247,251);
	/* color: #0081cc; */
}
/*应对不同的table类型*/
.tableArea>.normalHeader .ant-table .ant-table-container .ant-table-body{
	height: calc(100% - 40px);
	max-height: calc(100% - 40px) !important;
}
.tableArea>.doubleHeader .ant-table .ant-table-container .ant-table-body{
	height: calc(100% - 80px);
	max-height: calc(100% - 80px) !important;
}
/* 
.tableArea .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.tableArea .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th, 
.tableArea .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.tableArea .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th{
	padding:4px;
}
*/