@import '../../app.css';

.actionBarSimpleAct{
    display:flex;
    flex-direction: row;
}

.actionBarButtonGroup{
    flex:none;
	padding: 8px 0px 8px 24px;
}

.actionBarButtonGroup button{
    margin-right: 8px;
	border-radius: 8px;
}

/* 搜索栏 */
.actionBarSimpleSearch{
	padding: 8px 24px 8px 0;
	border-radius: 4px;
}
.actionBarSimpleSearch .ant-input-search{
	padding: 0;
	height: 32px;
	border-radius: 4px;
}
.actionBarSimpleSearch .ant-input-search .ant-input{
	height: 100%;
	padding-left: 10px;
	border-radius: 4px;
}

/* 自制收缩框部分 */
#actionBar + div > form{
	padding: 16px 16px 0px 16px;
}
#actionBar + div > form > .ant-row > .ant-col{
	margin-bottom: 14px;
}
#actionBar + div > form .ant-form-item{
	margin-bottom: 0px;
}
#actionBar + div > form .ant-form-item .ant-form-item-label{
	min-width: 1px;
}
#actionBar + div > form .ant-form-item .ant-form-item-label > label{
	/* width: 142px; */
	padding-left: 16px;
	text-align: right;
	display: inline-block;
	height: 32px;
	line-height: 32px;
}
#actionBar + div > form .ant-form-item .ant-form-item-control .ant-picker{
	width: 100%;
}
.optionArea{
	padding: 24px 24px 4px 24px;
}
.optionArea .optionItem{
	display: inline-block;
	width: 33.333%;
	margin-bottom: 20px;
}
.optionArea .optionItem label{
	display: inline-block;
	width: 130px;
	height: 32px;
	line-height: 32px;
	text-align: right;
}
.optionArea .optionItem input{
	display: inline-block;
	width: calc(100% - 130px);
	height: 32px;
	line-height: 32px;
	border-radius: 5px;
}
.optionArea .optionItem.btnGroup{
	text-align: right;
}
.optionArea .optionItem.btnGroup button{
	margin-left: 20px;
	border-radius: 5px;
	padding: 0;
	height: 32px;
	width: 64px;
	text-align: center;
	line-height: 32px;
}
.optionArea .optionItem.btnGroup button .iconfont{
	position: relative;
	top: 1px;
}

/* 左侧菜单栏三级样式（常态） */
/* .default-menu > .menu-body > li > .second-menu > li > .menu-item{
    display: flex;
    width: inherit;
    height: 40px;
    background-color: #000c17;
    line-height: 40px;
    padding-left: 32px;
    color: rgba(255,255,255,0.65);
}
.default-menu > #companyinformation{
    border-top: 1px solid #001a33;
	width: 100%;
    height: 60px;
	line-height: 60px;
	padding-left: 20px;
    display: flex;
    font-size: 14px;
    color: #fff;
}
.default-menu > #companyinformation > .companyname{
	margin-left: 10px;
} */
/* .tableArea .ant-table-container .ant-table-body tbody tr:hover{
	background-color: #f1f7fb;
} */
/* .tableArea .ant-table-container .ant-table-body tbody td .action-btn{
	color: #096dd9;
}
.tableArea .ant-table-container .ant-table-body tbody td .action-btn:hover,
.tableArea .ant-table-container .ant-table-body tbody td .action-btn:active{
	color: #40a9ff;
}
.tableArea .ant-table-container .ant-table-body tbody td .action-btn .iconfont.icon-zhedie{
	font-size: 0.9rem;
	position: relative;
	top: 1px;
} */
.tableArea .ant-table-container .ant-table-body tbody td .ant-btn.ant-btn-link{
	padding: 0;
	height: 100%;
	line-height: 20px;
}
.tableArea .ant-table-container .ant-table-body tbody td .ant-btn.ant-btn-link .iconfont.icon-zhedie{
	font-size: 0.9rem;
	position: relative;
	top: 1px;
}

.tableArea .ant-table-container .ant-table-body tbody td .longtxt{
	height: 18px;
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.actionBarMoreAct{
    overflow: hidden;
}

.actionBarMoreAct .actionBarForm{
    padding:8px;
}

.actionBarMoreAct .ant-advanced-search-form .ant-form-item {
    display: flex;
    margin-bottom: 0px;
}
  
.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.actionBar input{
    height: 28px;
}