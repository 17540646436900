.form_control_btn{
	position: absolute;
	height: 60px;
	bottom: 0;
	background-color: white;
	border-top: 1px solid #e7e7e7;
	z-index: 2;
	line-height: 60px;
	text-align: center;
}
.form_control_btn button{
	position: relative;
	padding: 0;
	/* width: 100px; */
	height: 32px;
	line-height: 32px;
	margin-right: 20px;
}
.my-editing-area{
	background-color: #e5e5e5;
}