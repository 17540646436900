
.form-block .ant-divider-horizontal.ant-divider-with-text-right::after{
    width:0;
}

.form-block .ant-divider{
    margin-top: 0;
}

.form-block .form-ani{
    overflow: hidden
}

.ant-modal-body .ant-collapse-item{
    border-bottom: none;
}

.ant-modal-body .ant-collapse-item .ant-divider-inner-text{
    font-size: 12px;
}