@import '../app.css';

.view-block{
	background:#f0f2f5;
	flex: none;
	height: calc(100% - 40px);
	display:flex;
	flex-direction:row;
	overflow: hidden;
	padding-bottom: 0;
}
.view-block > div{
	height: 100%;
}
.view-block > .common-block{
	display: flex;
}
.table-block{
	flex: 1;
	height: 100%;
	display:flex;
	flex-direction:column;
	overflow: hidden;
	padding-bottom: 0;
	position: relative;
}
.table-block .CenterTip{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	height: 40px;
	line-height: 40px;
	width: 220px;
	text-align: center;
	font-size: 14px;
	color: white;
	background-color: rgba(0,0,0,0.8);
	border-radius: 5px;
	z-index: 10;
}
.tree-block{
	width: 276px;
	height: calc(100% - 8px);
	margin-top: 8px;
	position: relative;
}
.tree-block .tree-content{
	padding: 24px;
	height: calc(100% - 24px);
	background-color: white;
	border-radius: 0 4px 4px 0;
}
.tree-block .tree-content .ant-input-affix-wrapper{
	padding: 0;
	margin-right: 0;
	height: 32px;
	line-height: 32px;
	border-radius: 4px;
}
.tree-block .tree-content .ant-input-affix-wrapper input{
	height: 100%;
	line-height: 32px;
	border-radius: 4px;
	padding-left: 10px;
}
.tree-block .tree-content .ant-input-affix-wrapper .anticon-search{
	color: rgb(0,129,204);
}
.tree-switch-block{
	position: absolute;
	top: 0;
	left: 0;
}
/* .tree-block{
	border-right: 1px solid #d9d9d9;
	padding-top: 15px;
	padding-left: 15px;
	display: flex;
	flex-direction: row;
}
.tree-content{
	display: flex;
	overflow: hidden;
}
.tree-switch-block{
	justify-content: center;
	align-items: center;
	display: flex;
	width:15px;
}
.tree-switch-block .tree-switch{
	width:25px;
	height:25px;
	min-width: 25px;
	margin-right: -15px
} */
.control-block{
	display: flex
}

.sample-search{
	margin-right: 5px;
	width:200px;
}

.sample-search .ant-input{
	height:28px;
	font-size: 12px
}

.ant-form label {
	font-size: 14px;
}

.ant-btn{
	font-size: 14px;
}

.ant-btn.btn-shape {
	padding:0 10px;
	height: 30px;
}

.ant-btn.btn-shape > span{
	margin-left:4px;
}

.ant-btn.btn-custom{
	height: 32px;
	line-height: 32px;
	padding: 0 15px;
	border-radius: 4px;
}

.ant-btn.btn-custom > span{
	font-size: 14px;
}

.ant-btn.btn-more:active,.ant-btn.btn-more:hover, .ant-btn.btn-more:focus, .ant-btn.btn-shape:focus, .ant-btn.btn-shape:active, .ant-btn.btn-without-style:focus, .ant-btn.btn-without-style:active{
	border-color: #d9d9d9;
	color:rgba(0, 0, 0, 0.65);
}

.ant-form-item-label{
	min-width: 80px;
}

/* 按钮红色 */
.ant-btn.btn-red:hover{
	border-color:var(--red);
	color:var(--white);
	background: var(--red);
}
/* 按钮蓝色 */
.ant-btn.btn-blue:hover{
	border-color:var(--blue);
	color:var(--white);
	background: var(--blue);
}
/* 按钮绿色 */
.ant-btn.btn-green:hover{
	border-color:var(--green);
	color:var(--white);
	background: var(--green);
}

/* 表格操作栏按钮 */
.action-btn{
	display: inline-block;
	font-size: 14px;
	width: auto;
	height: 20px;
	padding: 0 1.5px;
	/* border-radius: 50%; */
	line-height: 21px;
	color: #999;
	text-align: center;
}
.ant-divider-vertical{
	margin: 0 5px !important;
}
/* .action-btn:hover{
    background: var(--blue);
    color:var(--white);
} */
/* 表头 */
.ant-table-small > .ant-table-content .ant-table-header ,.ant-table-thead{
	background: #f5f6f7;
}

/* modal */
.ant-modal-close-x{
	height: 32px !important;
	width: 32px !important;
	line-height: 32px !important;
	color:rgba(255, 255, 255, 1);
}

.ant-modal-close-x:hover{
	color:rgba(255, 255, 255, .65);
	background-color: red;
}

.ant-design-draggable-modal .ant-modal-close{
	top: 10px;
	right: 10px;
}
.ant-design-draggable-modal .ant-modal-header{
	border-radius: 4px 4px 0 0;
}
.ant-design-draggable-modal .ant-modal-header .ant-design-draggable-modal-title{
	padding: 0;
	padding-left: 15px;
	height: 52px;
	line-height: 52px;
	background: rgba(37,140,199,1);
	border-radius: 4px 4px 0 0;
	color: #fff;
	font-weight: normal;
	font-size: 16px;
}

.ant-select-selection--single{
	height:28px;
	font-size: 12px;
	line-height: 28px;
}

.ant-select-selection-item{
	/*white-space: nowrap;*/
	/*display: inline-block;*/
	width: 83px;
	/*overflow: hidden;*/
	/*text-overflow: ellipsis;*/
	/*line-height: 0.9;*/
}

.ant-select-selection__rendered{
	line-height: 26px;
}

/* 树形下拉框 */
.ant-select-tree-dropdown > div{
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100vh;
}

.ant-select-tree-dropdown .ant-select-tree{
	overflow: auto;
	flex: 1;
}

.z-index {
	z-index:100;
}

.span {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	padding: 0;
	line-height: 100vh;
	z-index: 100;
}

.span-par{
	width: 100%;
	height: 100vh;
}

.span-model-par{
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 100vw;
}

.none {
	display: none;
}

/* 新增页面 */
#all-content{
	padding: 24px;
	padding-top: 8px;
	background-color: #f0f2f5;
	margin-bottom: 60px;
	border-radius: 4px;
}
#all-content > #content{
	padding: 16px 24px 1px 24px;
	background-color: white;
	border-radius: 4px;
}
/*伸缩控制*/
#content .ant-collapse-header{
	padding: 0;
	height: 25px;
}
#content .ant-collapse-header .ant-collapse-arrow{
	top: 10px;
	right: 0;
}
#content .ant-collapse-header .ant-divider{
	position: relative;
	top: 10px;
	margin: 0;
	padding-right: 18px;
}
#content .ant-collapse-header .ant-divider .ant-divider-inner-text{
	font-size: 16px;
	color: rgb(0,0,0);
	padding: 0;
	position: absolute;
	top: -12px;
	padding-right: 10px;
	z-index: 1;
}
/*内容部分*/
#content .ant-collapse-content{
	margin-top: 10px;
}
#content .ant-collapse-content .ant-collapse-content-box{
	padding: 0;
}
#content .ant-collapse-content .ant-collapse-content-box > .ant-row{
	margin: 0 !important;
}
#content .ant-collapse-content .ant-collapse-content-box > .ant-row > .ant-col{
	margin-bottom: 10px;
}
#content .ant-collapse-content .ant-collapse-content-box > .ant-row > .ant-col.noMargin{
	margin-bottom: 0;
}
#content .ant-collapse-content .ant-collapse-content-box > .ant-row > .ant-col > .ant-form-item{
	margin: 0;
}
#content .ant-collapse-borderless{
	margin-bottom: 10px;
}
#content .ant-collapse-borderless > .ant-collapse-item{
	border-bottom: 0;
	background-color: white;
}
#content .ant-form-item-label{
	height: 32px;
	width: 142px;
}
#content .ant-form-item-label > label{
	display: block;
	text-align: right;
	line-height: 32px;
	color: rgba(0,0,0,0.65);
}
/*带按钮的输入框部分*/
#content .InputBtnHolder .ant-form-item-label{
	flex: none;
}
#content .InputBtnHolder .ant-form-item-control{
	flex: none;
	width: calc(100% - 142px);
}
#content .InputBtnBlock{
	display: flex;
}
#content .InputBtnBlock input{
	padding: 4px 11px;
	height: 32px;
	flex: none;
	width: calc(100% - 32px);
}
#content .InputBtnBlock button{
	background-color: #f5f5f5;
	color: black;
	height: 32px;
	width: 32px;
	border-left: 0;
	padding: 0;
}
#content .InputBtnBlock.modifiable input,
#content .InputBtnBlock.modifiable button{
	background-color: white;
}
/*表格部分*/
#content .ant-table-content{
	border: 1px solid #e7e7e7;
}
#content .ant-table-content .ant-table-thead > tr,
#content .ant-table-content .ant-table-tbody > tr{
	height: 45px;
}
#content .ant-table-content .ant-table-thead > tr > th,
#content .ant-table-content .ant-table-tbody > tr > td,
#content .ant-table-content .ant-table tfoot > tr > th,
#content .ant-table-content .ant-table tfoot > tr > td{
	padding: 0;
}
#content .ant-table-content .ant-table-thead > tr > th,
#content .ant-table-content .ant-table-tbody > tr > td{
	border-right: 1px solid #e7e7e7;
}
#content .ant-table-content .ant-table-thead > tr > th{
	text-align: center;
}
#content .ant-table-content .ant-table-thead > tr > th:nth-last-child(1),
#content .ant-table-content .ant-table-tbody > tr > td:nth-last-child(1){
	border-right: 0;
}
#content .ant-table-content .ant-table-tbody > tr > td{
	padding: 0 5px;
}
#content .ant-table-content .ant-table-tbody > tr > td .ant-form-item{
	margin-bottom: 0;
}
/*表格部分·带加减的表格*/
#content .ant-collapse-content-box .ctrltable{
	border-radius: 4px;
}
#content .ant-collapse-content-box .ctrltable tr{
	height: 40px;
}
#content .ant-collapse-content-box .ctrltable tr .ant-form-item{
	margin-bottom: 0;
}
#content .ant-collapse-content-box .ctrltable>thead>tr{
	background-color: rgb(250,250,250);
	border: 1px solid rgb(231,231,231);
}
#content .ant-collapse-content-box .ctrltable th,
#content .ant-collapse-content-box .ctrltable td{
	border-right: 1px solid rgb(231,231,231);
}
#content .ant-collapse-content-box .ctrltable>thead>tr>th{
	text-align: center;
}
#content .ant-collapse-content-box .ctrltable>tbody>tr{
	border: 1px solid rgb(231,231,231);
	border-top: 0;
}
/*表格部分·自制表格部分*/
#content .customtable{
	width: 100%;
	border: 1px solid rgb(231,231,231);
}
#content .customtable tr{
	border-bottom: 1px solid rgb(231,231,231);
}
#content .customtable tr:hover{
	background-color: rgb(250,250,250);
}
#content .customtable tr td{
	border-right: 1px solid rgb(231,231,231);
}
#content .customtable tr td.tablelable{
	width: 80px;
	text-align: center;
}
#content .customtable tr td>.ant-form-item{
	margin-bottom: 0;
	padding: 0 10px;
}
#content .customtable tr td>.ant-form-item label{
	word-wrap: break-word;
	white-space: pre-wrap;
}
/*表格部分·长表格部分*/
#content .longtableholder .ant-table-content{
	overflow: auto;
}
#content .longtableholder .ant-table-content table{
	width: 1500px;
}
#content .longtableholder .ant-table-content table th{
	border-bottom: 1px solid rgb(231,231,231);
}
/*图表信息部分*/
#content .chartinfo{
	width: 290px;
	margin: auto;
}
#content .chartinfo .ant-row{
	margin-bottom: 0;
}
#content .chartinfo .ant-row:nth-child(1){
	font-weight: bold;
}
#content .chartinfo .ant-row .ant-form-item-label{
	width: 155px;
}
/*富文本输入组件*/
#content .ant-collapse-content-box .reactQuill>.ant-col{
	margin-bottom: 0 !important;
}
#content .ant-collapse-content-box .reactQuill>.ant-col .ql-container{
	height: calc(100% - 43px);
}

/*新增页面中的modal*/
.ant-design-draggable-modal .ant-modal-body{
	padding: 15px;
	height: calc(100% - 53px);
}
.ant-design-draggable-modal .ant-modal-body #btnZuidahua{
	position: absolute;
	top: 10px;
	right: 44px;
	background-color: rgba(0,0,0,0);
	border: 0;
	padding: 0;
	margin: 0;
	width: 32px;
	height: 32px;
	text-align: center;
	cursor: pointer;
}
.ant-design-draggable-modal .ant-modal-body #btnZuidahua>div{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	width: 16px;
	height: 16px;
	display: inline-block;
	text-align: center;
	line-height: 16px;
	color: white;
}
.ant-design-draggable-modal .ant-modal-body #btnZuidahua:hover{
	background-color: rgba(0,0,0,0.1);
}
.ant-design-draggable-modal .ant-modal-body #btnZuidahua:hover>div{
	border-color: rgb(179,215,235);
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse{
	background-color: #fff;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-header{
	padding: 0 !important;
	padding-right: 0 !important;
	position: relative;
	height: 22px;
	margin-top: 15px;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse:nth-child(2) .ant-collapse-header{
	margin-top: 0;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-header > .ant-divider{
	position: relative;
	top: 10px;
	margin: 0;
	padding-right: 35px;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-header > .ant-divider > .ant-divider-inner-text{
	font-size: 16px;
	padding: 0;
	position: absolute;
	top: -12px;
	padding-right: 10px;
	background-color: white;
	z-index: 11;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box{
	padding: 0;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box .ant-form-item{
	margin-bottom: 0;
	margin-top: 15px;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box .ant-form-item > .ant-form-item-label{
	width: 135px;
	text-align: right;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box .ant-form-item > .ant-form-item-control .ant-input-suffix{
	width: 32px;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box .ant-form-item > .ant-form-item-control .ant-input-suffix > span{
	margin: 0 10px;
}
/*modal内树组件*/
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box .ant-form-item .ant-input-affix-wrapper{
	padding: 0;
	margin-right: 0;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content > .ant-collapse-content-box .ant-form-item .ant-input-affix-wrapper input{
	height: 32px;
	line-height: 32px;
	padding-left: 10px;
}

.ant-design-draggable-modal .ant-modal-body .ant-collapse-content .ant-input-group-addon{
	width: 57px;
}
.ant-design-draggable-modal .ant-modal-body .ant-collapse-content .ant-input-group-addon .iconfont{
	position: relative;
	top: 1px;
}
.ant-design-draggable-modal .ant-modal-footer{
	padding: 10px;
}
.ant-design-draggable-modal .ant-modal-content{
	flex: none !important;
	width: 100%;
	height: 100%;
	border-radius: 4px !important;
}
.ant-design-draggable-modal .ant-modal-body .ant-form-item{
	margin-bottom: 14px;
}
.ant-design-draggable-modal .ant-modal-body .ant-form-item .ant-picker{
	width: 100%;
}
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-table-wrapper,
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-spin-nested-loading,
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-spin-container,
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-table-container{
	height: 100%;
}
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-table{
	height: calc(100% - 45px);
}
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-table + .ant-pagination{
	margin-bottom: 0;
}
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-table-body{
	height: calc(100% - 35px);
	max-height: calc(100% - 35px) !important;
	overflow: auto !important;
}
.ant-design-draggable-modal .ant-modal-body .tableholder .ant-table-body .longtxt{
	height: 18px;
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
/* 自制提示框组件样式 */
#customtip{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(255,255,255,0.4);
	z-index: 10;
}
#customtip>div{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	height: 40px;
	line-height: 40px;
	color: white;
	padding: 0 10px;
	border-radius: 4px;
	text-align: center;
	background-color: black;
}
#customtip>div>img{
	position: relative;
	bottom: 2px;
	width: 16px;
	height: 16px;
	margin-right: 10px;
}
/*下拉表格样式*/
.ant-menu .tableSelectBlock{
	overflow: hidden;
}
.ant-menu .tableSelectBlock .tableSelectActionBar{
	margin-bottom: 0;
}
.ant-menu .tableSelectBlock .tableSelectActionBar .ant-col{
	margin-bottom: 5px;
}
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper,
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper .ant-spin-nested-loading,
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container,
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container{
	height: 100%;
}
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table{
	height: calc(100% - 45px);
}
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body{
	height: calc(100% - 40px);
}
.ant-menu .tableSelectBlock .tableholder .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body .longtxt{
	height: 18px;
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
/* ?提示 */
.detailIcon{
	position: absolute;
	top: 25px;
	left: -33px;
	background-color: #1890ff;
	color: white;
	width: 14px;
	height: 14px;
	text-align: center;
	line-height: 14px;
	border-radius: 100%;
	cursor: pointer;
}